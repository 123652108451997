body {
    padding-top: 5rem;
  }
  .starter-template {
    padding: 3rem 1.5rem;
    text-align: center;
  }

  body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  main {
    flex: 1;
  }
 

 .logo{
  width: 40px;
  margin-right: 10px;
 }
 .error{
  color: red;
 }

 
.content-wrapper {
  max-width: 90%;
  margin: 0 auto;
}

h1 {
  margin-bottom: 30px;
}

.option-card {
  margin-bottom: 20px;
}

.footer {
  background-color: rgb(81, 107, 235);
  padding: 20px 0;
  color: #fff;
  margin-top: 80px;
}

.footer p {
  margin-bottom: 0;
}

.footer-logo {
  height: 40px; /* Adjust the height as needed */
}
